import React from 'react'
import image from './../../assets/megaDanceFooter.png'
import image2 from './../../assets/searchGroup.png'
import './Footer.css'
import MegaDanceLogoNavbar from '../MegaDanceLogoNavbar/MegaDanceLogoNavbar'
const Footer = () => {
    const menuItems = ["Home", "Hardware Requirements","Contact"]
  return (
      <div className='footer'>
          <div className='footerUpper'>
              <div
                  style={{
                  marginTop:"20px"
              }}
              >
                <MegaDanceLogoNavbar/>
              </div>
              <div className='menuItems'>
                  {
                      menuItems?.map((item) => {
                          return (
                              <div>
                                  {item}
                                  </div>
                          )
                      })
                  }
                  
              </div>
              <div className='footerImage2Container'>
                  
                  <img
                      className='footerImage2'
                      src={image2}
                  />
              </div>
          </div>
          <div className='copyRightLine'>
              Copyright ©  2024 aashu. all rights reserved
          </div>
    </div>
  )
}

export default Footer