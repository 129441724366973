import React from "react";
import Header from "./components/Header/Header";
import WelcomeToMegaDance from "./components/WelcomeToMegaDance/WelcomeToMegaDance";
import LeadershipBoard from "./components/LeadershipBoard/LeadershipBoard";
import Footer from "./components/Footer/Footer";
import Features from "./components/Features/Features";
import Navbar from "./components/Navbar/Navbar";
import ContactUs from "./components/ContactUs/ContactUs";
import Home from "./components/Home/Home";
import { Route, Routes } from "react-router-dom";
import Hardware from "./components/Hardware/Hardware";

const App = () => {
  return (
    <div>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/hardware" element={<Hardware />} />
      </Routes>

      <Footer />
    </div>
  );
};

export default App;
