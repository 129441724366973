import React from 'react'
import computerImage from '../../assets/image.png'
import contactus from '../../assets/contactUsPara.png'
import chair2 from '../../assets/chairPic.png'
import chair from '../../assets/chairPic.png'
import game from '../../assets/gamePic.png'

import './HardwareSystemYellowBackground.css'

const HardwareSystemYellowBackground = () => {
  return (
      <div>
          <div>

              <div className='hardwareyellowbackgroundheading'>
                  What <span
                      style={{
                          margin: " 0 5px ",
                  color: "#FFD200"
                  }}
                  > you Need</span> to <span
                      style={{
                          marginLeft: "5px",

                  color: "#FF0000"


                      }}
                  >play game</span>
              </div>
              <div className='imageTwo'>
                  <img className='yellowB ' src={game} />

              </div>
              <div className='yellowBackground'>
                  <img className='yellowBackgroudImage' src={computerImage} />
                 
                  <div className='imageOne'>
                      <img className='yellowB ' src={game} />

                  </div>
                  <div className='windowBasedCom'>
                      <div className='windowBasedComputer'>
                          Windows-based<span style ={{
                              color: "#ff0000",
                              marginLeft : "8px"
                          }}>
                              {"  "}  Computer</span> 
                      </div>
                      <div className='windowPoints'>
                          <div className='windowPoint'>
                              <span className='windowBasedComputerPoint'>.   </span>
                              Windows-based computer with at least 8
                              GB of RAM, i5 processor, and a compatible
                              graphics card.
                          </div>
                          <div className='windowPoint'>
                              <span className='windowBasedComputerPoint'>.   </span>
                              USB-compatible Dance Pads (2-player
                              capability).
                          </div>
                          <div className='windowPoint'>
                              <span className='windowBasedComputerPoint'>.   </span>
                              Router with Static IP for seamless connection
                              between the admin and student machines.
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </div>
  )
}

export default HardwareSystemYellowBackground