import React from 'react'
import './ContactUsForm.css'
const ContactUsForm = () => {
  return (
      <div className='contactUsForm'>
          <div  className='contactRow'>
              <div
              className='contactSection'
              >
                  <label>
                      First Name
                  </label>
                  <input/>
              </div>
              <div
                  className='contactSection'
              >
                  <label>
                      First Name
                  </label>
                  <input />
              </div>
          </div>
          <div className='contactRow'>
              <div className='contactSection'>
                  <label>
               Email 
                  </label>
                  <input />
              </div>
              <div className='contactSection'>
                  <label>
                     Phone Number
                  </label>
                  <input />
              </div>  
              
          </div>
          <div className='contactSection'>
              <label>
                  Message
              </label>
              <textarea/>
              
          </div>
          <div className='buttonPlace'>
              
          <button
              
              className='sendMessage'
              >Send Message</button>
              </div>
    </div>
  )
}

export default ContactUsForm