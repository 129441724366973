import React from 'react'
import Heading from '../Heading/Heading'

import './WelcomeToMegaDance.css'
const WelcomeToMegaDance = () => {
  return (
      <div>
          <div className='welcomeToMegaDanceHeading'>
              Welcome to Mega
              <span>Dance !</span>
         </div>
          <div className='welcomeToMegaDancePara'>
              {" "}    Welcome to the
              <span>
                  {" "} Mega Dance  
              </span>
             {" "}  Revolution by 
              <span>
                  {" "}     Traveling Tykes!    {" "} 
              </span>
              We bring excitement and
              <span>
                  
                  {" "}      high-energy fun 
              </span>
              
              to your events with our unique 
              
              <span>
                  {" "}      Dance Dance Revolution-inspired software </span>. Perfect for backyard parties, school events, or large corporate gatherings, our  {" "} 
              <span>
                  
           
                  {" "}    game brings kids    </span>   {" "}  together to enjoy an engaging dance experience! With our Windows-based application, <span>
                  {" "}  kids can step </span> up onto interactive dance pads, follow <span>
                  {" "}   rhythm-based arrow cues, </span>    {" "} and compete <span>
                  for high scores. </span>   {" "} Let <span>   {" "}  Traveling </span> Tykes transform your next event with a fun, tech-savvy dance experience <span>
                  {" "}    everyone will love. </span> 
          </div>
    </div>
  )
}

export default WelcomeToMegaDance