import React from 'react'
import logo from './../../assets/megaDanceNavbar.png'
import './MegaDanceLogoNavbar.css'

const MegaDanceLogoNavbar = () => {
  return (
      <div className='megaDanceLogoNavbar'>
          
      <img
        style={{
          width : '60px'
        }}
      src={logo}
      />

    </div>
  )
}

export default MegaDanceLogoNavbar