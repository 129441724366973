import React from 'react'
import Heading from '../Heading/Heading';
import img1 from '../../assets/scorecard1.png';
import img2 from '../../assets/leadereboard2.png';
import img3 from '../../assets/leaderboardCard3.png';
import './LeadershipBoard.css'




const LeadershipBoard = () => {
  return (
      <div>
          <Heading
          heading ={"Leadership"}
          
          />
          <div className='leadershipBoard'>
              <div className='leadershipBoardOne'>
                  <img src={img1} />
              </div>
              <div className='leadershipBoardThree'>
                  <img src={img3} />
              </div>
              <div className='leadershipBoardTwo'>
                  <img src ={img2} />
              </div>
             
          </div>
    </div>
  )
}

export default LeadershipBoard