import React from 'react'
import Heading from '../Heading/Heading'
import './Features.css';
import img1 from '../../../src/assets/groupImage1.png'
import img2 from '../../../src/assets/groupImage2.png'

const Features = () => {
  return (
      <div>
          <Heading
          heading={"Features"}
          />
          <div className='featuresImage'>
              <img
                  src ={img1}
              
              />
              <img
                  src ={img2}
              
              />
              
          </div>
    </div>
  )
}

export default Features