import React from 'react'
import './HeaderAtTop.css'

const HeaderAtTop = ({
    image,header
}) => {
  return (
      <div className='HeaderAtTop'>
          <img src={  image}/>
          <div className='headerOnTopText'>
      {header}</div>
          
    </div>
  )
}

export default HeaderAtTop