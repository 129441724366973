import React from 'react'
import Header from '../Header/Header'
import WelcomeToMegaDance from '../WelcomeToMegaDance/WelcomeToMegaDance'
import Features from '../Features/Features'
import LeadershipBoard from '../LeadershipBoard/LeadershipBoard'

const Home = () => {
  return (
      <div>
          <Header/>

          <WelcomeToMegaDance />
          <Features />
          <LeadershipBoard/>
    </div>
  )
}

export default Home