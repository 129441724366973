import React from 'react'
import img from '../../assets/working_desk_20 1.png'
import HeaderAtTop from '../HeaderAtTop/HeaderAtTop'
import chair from '../../assets/chairPic.png'
import para from '../../assets/contactUsPara.png'

import './Hardware.css'
import HardwareSystemYellowBackground from '../HardwareSystemYellowBackground/HardwareSystemYellowBackground'

const Hardware = () => {
    return (
        <div>
            <HeaderAtTop
                image={img}
                header={"To enjoy Mega Dance Revolution, you'll need"}
            />
            <HardwareSystemYellowBackground />

            <div className='bottomSection'>
                <div className='hardwareyellowbackgroundheading'>
                    What <span
                        style={{
                            margin: " 0 5px ",
                            color: "#FFD200"
                        }}
                    > you Need</span> to <span
                        style={{
                            marginLeft: "5px",

                            color: "#FF0000"


                        }}
                    >Our Software</span>
                </div>
                <div
                    className='needsoftware'

                >
                    <div>
                        <img
                            className='chairImage'

                            src={chair}
                        />
                    </div>
                    <div>

                        <img
                            className='paraImage'

                            src={para}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hardware