import React from "react";
import "./ContactUsInformation.css";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { GoLocation } from "react-icons/go";

const ContactUsInformation = () => {
    const contactInformation = {
        email: {
            value: "abc@mi.com",
            icon: <AiOutlineMail />,
        },
        contact: {
            value: "+123 456 7890",
            icon: <AiOutlinePhone />,
        },
        location: {
            value: "123, Main Street, Your City",
            icon: <GoLocation />,
        },
    };

    return (
        <div className="contactUsInformation">
            <div className="header">
                <h2>Contact Information</h2>
                <p>Say something to start a live chat!</p>
            </div>
            <div className="infoList">
                {Object.entries(contactInformation).map(([key, info]) => (
                    <div key={key} className="infoItem">
                        <span className="icon">{info.icon}</span>
                        <span className="value">{info.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContactUsInformation;
