import React from 'react'
import contactUsBack from '../../../src/assets/contactUsPage.png'
import HeaderAtTop from '../HeaderAtTop/HeaderAtTop'
import ContactUsInformation from '../ContactUsInformation/ContactUsInformation'
import Heading from '../Heading/Heading'
import './ContactUs.css'
import ContactUsForm from '../ContactUsForm/ContactUsForm'

const ContactUs = () => {
  return (
      <div>
          <HeaderAtTop
              header ={"Contact Us"}
          image={contactUsBack}
          />
          <Heading
          heading={"Contact Us"}
          />
          <p className='headingPara'>
              Any question or remarks? Just write us a message!
          </p>
          <div className='contactUsPageSections'>
          <ContactUsInformation />
          <ContactUsForm/>
              </div>
          
    </div>
  )
}

export default ContactUs