import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.css';

import MegaDanceLogoNavbar from '../MegaDanceLogoNavbar/MegaDanceLogoNavbar';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const navItems = [
        { menu: "Home", path: "/" },
        { menu: "Contact Us", path: "/contactus" },
        { menu: "Hardware Requirements", path: "/hardware" },
    ];

    return (
        <div className="navbar">
            <div className="logo-container">
                <MegaDanceLogoNavbar />
            </div>
            <div className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
                ☰
            </div>
            <div className={`navbar-items ${menuOpen ? 'open' : ''}`}>
                {navItems.map((item, index) => (
                    <div
                        key={index}
                        className={`nav-item ${location.pathname === item.path ? 'active' : ''}`}
                        onClick={() => setMenuOpen(false)} // Optional: Close the menu on navigation
                    >
                        <a
                            style={{
                                textDecoration: "none",
                                color : "black"
                        }}
                            
                            href={item.path}>{item.menu}</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Navbar;
