import React from 'react'
import image from '../../assets/homePage.png'
import Navbar from '../Navbar/Navbar'
import './Header.css'
const Header = () => {
  return (
      <div className='header'> 
          

          <img className='headerBackgroundImage' src={image} />
          

  
          
    </div>
  )
}

export default Header